
.login-wrapper {
    background: no-repeat url("../bg.jpg");
    height: 100vh;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-container {
    background-color: #fff;
    padding: 20px 50px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    margin: 0 10px;
}

.form-container.form-reg {
    width: 600px;
}

.form-reg > .form-title {
    margin-bottom: 20px;
}
.form-reg > form > .input-password-container {
    margin-bottom: 20px;
}
.form-title {
    font-size: 35px;
    font-weight: 500;
}


/*social auth*/
.form-socials {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 30px 0;
}

.form-socials__item {
    width: calc((100% - 20px) / 2);
    height: 70px;
    border-radius: 10px;
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%);
    transition: all .4ms ease;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px;
}

.form-socials__item:hover {
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 30%);

}

.form-socials__item > span {
    font-size: 20px;
    font-weight: 500;
}

.form-socials__item.vk > span {
    color: #fff;
}

.form-socials__item > img {
    object-fit: contain;
    width: 40%;
    height: 100%;
}

.form-socials__item.vk {
    background-color: #47b;
}


/*form styles*/
.form-container > form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
}

.form-container > form > button {
    height: 50px;
    background-color: #000;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.form-container > form > button:hover {
    background-color: rgba(0, 0, 0, 0.89);
}

.input-password-container {
    position: relative;

}

.input-password-container > input {
    border: none;
    width: 100%;
    height: 100%;
    outline: 0 none;

}

.input-password-container > input:focus-within .input-password-container {
    border-color: #0063d1;
    box-shadow: 0 1px 1px rgba(0, 99, 209, 0.075) inset, 0 0 8px rgba(0, 99, 209, 0.6);
}

.input-password-container > span {
    position: absolute;
    bottom: 15px;
    right: 20px;
    font-size: 25px;
}


.form-container > form > input, .input-password-container {
    width: 100%;
    background: 0 0;
    height: 60px;
    padding: 0 20px;
    border: 1px solid #666666;
    border-radius: 10px;
}

.form-container > form > input:focus {
    border-color: #0063d1;
    box-shadow: 0 1px 1px rgba(0, 99, 209, 0.075) inset, 0 0 8px rgba(0, 99, 209, 0.6);
    outline: 0 none;
}

.form-container > form > input:first-child {
    margin-bottom: 20px;
}

.form-container > form > a {
    margin: 20px 0;
}

.password-recover {
    margin: 20px 0;
    color: #1890ff;
    cursor: pointer;
}


@media screen and (max-width: 560px) {
    .form-socials {
        flex-direction: column;
    }

    .form-socials__item {
        transition: width 1s ease;
        width: 100% !important;
    }

    .form-socials__item:nth-child(1) {
        margin-bottom: 15px;
    }

}