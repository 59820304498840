.card-stat {
    min-height: 200px;
    width: 100%;
    color: #000;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%);
    height: 100%;
}

.card-stat__chart > span {
    font-size: 30px;
    padding: 10px;
    border-radius: 50%;
}

.card-stat__head {
    height: 70%;
    align-items: center;
    display: flex;
    border-bottom: 1px solid #00000012;
    padding: 10px 25px;
}

.card-stat__footer {
    padding: 10px 25px;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #6b778c;
    height: 30%;
}

.card-stat__info {
    width: 90%;
}
.card-stat__title {
    font-size: 14px;
    font-weight: 500;
    color: #0000007a;
}
.card-stat__count {
    font-size: 25px;
    font-weight: 500;
}

@media screen and (max-width: 991px){
    .card-stat {
        margin-bottom: 20px;
    }
}
