.logo {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    font-size: 20px;
    color: #fff;
    transition: all .5s ease-in;
}

.logo.collapsed {
    font-weight: 600;
    font-size: 15px;
    transition: all .5s ease-in;
}

.logo > a {
    color: #fff;
}