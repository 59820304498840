/*.contacts > span > svg {*/
/*    font-size: 20px;*/
/*    fill: #9c9c9c;*/
/*}*/

/*new chat styles*/

.chat-wrapper {
  display: flex;
  height: 100%;
}

/*end new chat styles*/

.no-messages {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #999;
}

.center {
  position: absolute;
  top: 50%;
  left: calc(50% + 12rem);
  transform: translate(-50%, -50%);
}
.pic {
  width: 4rem;
  height: 4rem;
  background-size: cover !important;
  background-position-x: center !important;
  background-repeat: no-repeat !important;
  border-radius: 50%;
}
.contact {
  position: relative;
  margin-bottom: 1rem;
  padding-left: 5rem;
  height: 4.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact:hover {
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);
}

.contact .date {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.6rem;
  color: #999;
}

.contact .detail-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contact .pic {
  position: absolute;
  font-weight: 600;
  left: 0;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact .name {
  font-weight: 500;
  margin-bottom: 0.125rem;
}
.contact .message,
.contact .seen {
  font-size: 0.9rem;
  color: #999;
}
.contact .badge {
  box-sizing: border-box;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  font-size: 0.9rem;
  padding-top: 0.125rem;
  border-radius: 1rem;
  top: 0;
  left: 2.5rem;
  background: #333;
  color: white;
}
.contacts {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-6rem, -50%);
  width: 24rem;
  height: 32rem;
  padding: 1rem 2rem 1rem 1rem;
  box-sizing: border-box;
  border-radius: 1rem 0 0 1rem;
  cursor: pointer;
  background: white;
  box-shadow: 0 0 8rem 0 rgba(0, 0, 0, 0.1),
    2rem 2rem 4rem -3rem rgba(0, 0, 0, 0.5);
  transition: transform 500ms;
}

.contacts-container {
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
  max-height: 80%;
}

.contacts h2 {
  margin: 0.5rem 0 1.5rem 5rem;
}
.contacts > span > svg {
  font-size: 1.5rem;
  position: absolute;
  left: 2.25rem;
  fill: #999;
  transition: color 200ms;
}
.contacts > span > svg:hover {
  fill: #666;
}
.contacts .contact:last-child {
  margin: 0;
}
.contacts:hover {
  transform: translate(-23rem, -50%);
}
.chat {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 24rem;
  height: 38rem;
  z-index: 2;
  box-sizing: border-box;
  border-radius: 1rem;
  background: white;
  box-shadow: 0 0 8rem 0 rgba(0, 0, 0, 0.1),
    0rem 2rem 4rem -3rem rgba(0, 0, 0, 0.5);
}
.chat .contact.bar {
  flex-basis: 3.5rem;
  flex-shrink: 0;
  margin: 1rem;
  box-sizing: border-box;
}
.chat .messages {
  min-height: 450px;
  padding: 1rem;
  background: #f7f7f7;
  flex-shrink: 2;
  overflow-y: auto;
  box-shadow: inset 0 2rem 2rem -2rem rgba(0, 0, 0, 0.05),
    inset 0 -2rem 2rem -2rem rgba(0, 0, 0, 0.05);
}
.chat .messages .time {
  font-size: 0.5rem;
  font-weight: 500;
  padding: 0.25rem 0rem;
  border-radius: 2rem;
  color: #999;
  width: -moz-fit-content;
  width: fit-content;
}

.chat .messages .time .left {
}

.chat .messages .time .right {
}
.chat .messages .message {
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  background: #fff;
  border-radius: 1.125rem 1.125rem 1.125rem 0;
  min-height: 2.25rem;
  width: fit-content;
  max-width: 66%;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.075),
    0rem 1rem 1rem -1rem rgba(0, 0, 0, 0.1);
}
.chat .messages .message.parker {
  margin: 1rem 1rem 1rem auto;
  border-radius: 1.125rem 1.125rem 0 1.125rem;
  background: #001628;
  color: white;
}
.chat .messages .message .typing {
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0rem;
  box-sizing: border-box;
  background: #ccc;
  border-radius: 50%;
}
.chat .messages .message .typing.typing-1 {
  animation: typing 3s infinite;
}
.chat .messages .message .typing.typing-2 {
  animation: typing 3s 250ms infinite;
}
.chat .messages .message .typing.typing-3 {
  animation: typing 3s 500ms infinite;
}
.chat .input {
  box-sizing: border-box;
  flex-basis: 4rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 0 0.5rem 0 1.5rem;
}

.chat .input span.disabled > svg {
  cursor: unset;
  fill: #999999;
}
.chat .input span.disabled > svg:hover {
  fill: #999999;
  cursor: unset;
}

.chat .input span > svg {
  font-size: 1.5rem;
  margin-right: 1rem;
  fill: #666;
  cursor: pointer;
  transition: color 200ms;
}

.chat .input span > svg:hover {
  fill: #333;
}

.chat .input input {
  border: none;
  background-image: none;
  background-color: white;
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  border-radius: 1.125rem;
  flex-grow: 2;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1),
    0rem 1rem 1rem -1rem rgba(0, 0, 0, 0.2);
  font-family: Red hat Display, sans-serif;
  font-weight: 400;
  letter-spacing: 0.025em;
}
.chat .input input::placeholder {
  color: #999;
}
@keyframes typing {
  0%,
  75%,
  100% {
    transform: translate(0, 0.25rem) scale(0.9);
    opacity: 0.5;
  }
  25% {
    transform: translate(0, -0.25rem) scale(1);
    opacity: 1;
  }
}
.pic.stark {
  background-image: url("https://vignette.wikia.nocookie.net/marvelcinematicuniverse/images/7/73/SMH_Mentor_6.png");
}
.pic.banner {
  background-image: url("https://vignette.wikia.nocookie.net/marvelcinematicuniverse/images/4/4f/BruceHulk-Endgame-TravelingCapInPast.jpg");
}
.pic.thor {
  background-image: url("https://vignette.wikia.nocookie.net/marvelcinematicuniverse/images/9/98/ThorFliesThroughTheAnus.jpg");
}
.pic.danvers {
  background-image: url("https://vignette.wikia.nocookie.net/marvelcinematicuniverse/images/0/05/HeyPeterParker.png");
}
.pic.rogers {
  background-image: url("https://vignette.wikia.nocookie.net/marvelcinematicuniverse/images/7/7c/Cap.America_%28We_Don%27t_Trade_Lives_Vision%29.png");
}

/*modal chat*/
.chat-modal {
  z-index: 5;
  position: fixed;
  right: 0;
  bottom: 0;
  background: #001529;
  color: #fff;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.5s all;
  max-height: 35px;
  max-width: 200px;
  border-radius: 10px 10px 0 0;
  box-shadow: -7px -4px 7px 0px rgb(0 0 0 / 10%);
}

.chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px 10px;
  z-index: 3;
}

.chat-header
  > span
  > .MuiBadge-badge:not(
    .MuiBadge-anchorOriginTopRightRectangle.MuiBadge-invisible
  ) {
  background-color: #e13432 !important;
  transform: scale(1) translate(80%, -50%);
}

.chat-content {
  background-color: #fff;
  color: #000;
  opacity: 0;
}

.chat-modal.open {
  max-width: 350px;
  max-height: 550px;
  height: auto;
  width: 100%;
}

.chat-modal.full {
  max-width: 650px;
  max-height: 550px;
  height: auto;
  width: 100%;
}

.chat-modal.open > .chat-content {
  opacity: 1;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.chat-modal.full > .chat-content {
  opacity: 1;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.chat-support {
  display: flex;
  justify-content: space-between;
  background-color: #0361d10f;
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.chat-support:hover {
  background-color: rgba(3, 97, 209, 0.07);
}

.chat-support__item {
  color: #0361d1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chat-support__item > span {
  color: #586f7c;
  font-weight: 500;
  font-size: 12px;
}

.chat-support__item.telegram > svg {
  color: #2da4d7;
  font-size: 25px;
}

.chat-support__item.whatsapp > svg {
  color: #33f269;
  font-size: 25px;
}

.chat-support__item.vk > svg {
  color: #0077fe;
  font-size: 25px;
}

/*dialogs*/

.dialogs {
  overflow-y: scroll;
  height: 350px;
}

.dialogs::-webkit-scrollbar-track,
.messages-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
}

.dialogs::-webkit-scrollbar,
.messages-content::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.dialogs::-webkit-scrollbar-thumb,
.messages-content::-webkit-scrollbar-thumb {
  background-color: #0361d121;
  border-radius: 5px;
}

.dialogs-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 5px;
}

.dialogs-item:hover {
  background-color: #0361d10f;
}

.dialogs-item > span > img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
  object-fit: cover;
}

.dialogs-item
  > span
  > .MuiBadge-anchorOriginTopRightRectangle:not(
    .MuiBadge-anchorOriginTopRightRectangle.MuiBadge-invisible
  ) {
  transform: scale(1) translate(-10%, -10%);
}

.chat-modal.full .dialogs-info {
  width: 100%;
}

.dialogs-info {
  position: relative;
  width: 80%;
  margin-left: 10px;
}

.dialogs-info > div > span {
  font-size: 13px;
  max-width: 60%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
}

.dialogs-info > div > div {
  display: flex;
  align-items: center;
}

.dialogs-info > div > div > span {
  color: rgb(0 0 0 / 45%);
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
  margin-top: 5px;
}

.dialogs-time {
  position: absolute;
  right: 10px;
  top: 0;
  font-size: 12px;
}

.no-dialogs {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  color: #2f4550;
}

/*messages*/
.messages {
  margin: 0;
  flex-direction: column;
  height: 400px;
}

.messages.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.messages-header > a {
  display: flex;
  align-items: center;
  width: 100%;
  color: #000;
}

.messages-header {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #f4f4f9;
  border-radius: 10px;
  padding: 10px;
  color: #000;
}

.messages-header > svg {
  background-color: #dedede;
  border-radius: 50px;
}

.messages-header > svg:hover {
  background-color: #d0d0d0;
}

.messages-content {
  overflow-y: scroll;
  height: 100%;
  margin: 10px 0 50px 0;
}

.chat-group:last-child {
  margin-bottom: 120px;
}

.messages-header > img,
.messages-header > a > img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  object-fit: cover;
  margin: 0 10px;
}

.messages-header__info {
  display: flex;
  flex-direction: column;
}

.messages-header__info > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.messages-form {
  position: absolute;
  bottom: 0;
  width: 95%;
  /* justify-content: space-between; */
  /* display: flex; */
  background-color: #f4f4f9;
  padding: 10px;
  border-radius: 10px 10px 0 0;
}

.messages-form > form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.messages-form > form > textarea {
  width: 100%;
  border: 1px solid #e3e3e3;
  background-color: #ffff;
  border-radius: 5px;
  height: 30px;
  padding-left: 10px;
  padding-top: 5px;
}

.messages-form > form > svg {
  color: #0361d1;
}

.chat-message {
  background: #0361d11f;
  color: #000;
  width: fit-content;
  max-width: 70%;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}

.chat-group-date {
  background: #f0f2f5;
  width: fit-content;
  margin: 0 auto;
  padding: 5px 15px;
  border-radius: 20px;
  color: #000000d9;
  margin-bottom: 10px;
}

.chat-group.no-messages {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.chat-send-message {
  width: 100%;
  min-height: 50px;
  max-height: 132px;
  /*position: absolute;*/
  /*bottom: 0;*/
}

.chat-send-message {
  padding: 10px 20px;
  background: #001529;
  display: flex;
  flex-direction: column;
}

.chat-send-message > img {
  width: 50px;
  margin: 10px;
}

.chat-send-message > form {
  width: 100%;
  display: flex;
  align-items: center;
}

.chat-send-message > form > textarea {
  width: 100%;
  border: none;
  border-radius: 6px;
  padding: 10px;
  background-color: #fff;
}

.chat-send-message > span {
  font-size: 25px;
  color: rgba(234, 234, 234, 0.78);
  margin-left: 20px;
  cursor: pointer;
}

.chat-send-message > span:hover {
  color: #fff;
}

.chat-messages_item {
  display: flex;
  position: relative;
  width: 100%;
}

.chat-messages_item.customer {
  justify-content: flex-end;
}

.chat-message.shop > .chat-time {
  right: -45px;
}

.chat-username {
  font-weight: 500;
}

.chat-message.customer > .chat-time {
  left: -45px;
}

.chat-message.support > .chat-time {
  right: -45px;
}

.chat-message.shop {
  background: #f0f2f5;
  color: #000;
}

.chat-message.support {
  background: #1890ff;
  color: #fff;
}

.chat-time {
  position: absolute;
  bottom: 0px;
  font-size: 13px;
  color: #b9b9b9;
}

.chat-text {
  display: flex;
  flex-direction: column;
  /*overflow: hidden;*/
  word-wrap: break-word;
}

.no-chats {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#icon-button-file {
  display: none;
}

.messages-form > form > input[type="text"] {
  width: 100%;
  border: 1px solid #e3e3e3;
  background-color: #fff;
  border-radius: 5px;
  height: 30px;
  padding-left: 10px;
  /*padding-top: 5px;*/
}

@media screen and (max-width: 768px) {
  .chat-modal.full .dialogs-info {
    width: 80%;
  }
  .chat-modal {
    z-index: 4;
  }
}
