.ant-layout.ant-layout-has-sider > .ant-layout, .ant-layout.ant-layout-has-sider > .ant-layout-content {
    width: calc(100% - 200px);
}

.ant-layout-sider-zero-width-trigger {
    z-index: 2;
}
.ant-table-tbody > tr > td {
    border-bottom: 3px solid #f0f0f0 !important;
}

/*files-order print*/

table.invoice_bank_rekv {
    border-collapse: collapse;
    border: 1px solid;
}

table.invoice_bank_rekv > tbody > tr > td, table.invoice_bank_rekv > tr > td {
    border: 1px solid;
}

table.invoice_items {
    border: 1px solid;
    border-collapse: collapse;
}

table.invoice_items td, table.invoice_items th {
    border: 1px solid;
}

table.invoice_bank_rekv-torg12 {
    border-collapse: collapse;
}

table.invoice_bank_rekv-torg12 > tbody > tr > td, table.invoice_bank_rekv-torg12 > tr > td {
    border: 1px solid;
}

table.invoice_bank_rekv-torg12 td, table.invoice_bank_rekv th {
    border: 1px solid;
}

.infinite-scroll-component h4 {
    color: #fff;
}

.infinite-scroll-component {
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

/*end styles files-order print*/

.analytics-user:hover {
    background-color: #0000000d;
    border-radius: 8px;
    cursor: pointer;
}

@media screen and (max-width: 500px) {
  body {
      overflow-x: hidden;
  }
}